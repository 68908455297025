.htHeader {
  padding: 10px 65px;
  box-shadow: 0 0.5px 2px 0 rgba(21, 34, 50, 0.08);
  background-color: #ffffff;
  display: flex;
  align-items: center;
}
.innerHeader {
  max-width: 80%;
  margin: 0 auto;
}
.ht-logo {
  width: 134px;
}
img {
  max-width: 100%;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.rightSideLogin {
  margin-left: auto;
}
.rightSideLogin ul {
  display: flex;
  align-items: center;
}
.rightSideLogin li {
  margin-left: 40px;
  padding-left: 28px;
  cursor: pointer;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #5a607f;
  position: relative;
}
.rightSideLogin li .dropdownMenu{
  position: absolute;
  position: absolute;
    background: #f2f3f8;
    left: 0;
    top: 40px;
    width: 200px;
    padding: 5px 0;
    box-shadow: 0 1.5px 3px 0 rgb(0 0 0 / 16%);
    z-index: 1000;

}
.rightSideLogin li .dropdownMenu .menuItem a{
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #3f3f41;
  position: relative;
  text-decoration: none;
  padding: 10px;
  margin-bottom: 1px;
  display: block;
  width: 100%;
}
.rightSideLogin li .dropdownMenu .menuItem:hover{
  background: #e4e4e4;
}

.rightSideLogin li .dropdownMenu:after {
	bottom: 100%;
	left: 26%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #f2f3f8;
	border-width: 20px;
	margin-left: -20px;
}


/* .rightSideLogin li:hover .dropdownMenu{
  display: block;
} */

.rightSideLogin li:first-child {
  margin-left: 0;
}
.rightSideLogin li.manage {
  background: url("../../assets/icon01.png") left center no-repeat;
}
.rightSideLogin li.lang {
  background: url("../../assets/icon02.png") left center no-repeat;
  display: flex;
  align-items: center;
}
.rightSideLogin li.profilePic {
  /* background: url("../../assets/user-img.png") left center no-repeat; */
  /* padding: 25px 0 25px 55px; */
  padding: 0;
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #131523;
}
.arrowBtn {
  background: url("../../assets/chevron@2x.png") left center no-repeat;
  width: 18px;
  height: 18px;
  display: block;
  transform: rotate(90deg);
  background-size: 100%;
}
.display-none {
  display: none;
}
.rightSideLogin li.contactSupport {
  background: url("../../assets/contact-support.svg") left center no-repeat;
  display: flex;
  align-items: center;
  background-size: 23px;
}