.__AssetDetails__ .ant-form-item {
  margin: 0;
}
.__AssetDetails__ .formGroup {
  width: 100%;
}
.__AssetDetails__ .formGroup input {
  max-width: 300px;
}
.__AssetDetails__ .formFiled {
  width: 100%;
  max-width: 100%;
}
.__AssetDetails__ .ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.__AssetDetails__ .addItems {
  cursor: pointer;
  margin-left: 15px;
}
.parentBlock2 {
  max-width: 80%;
  margin: 0 auto;
}
.parentBlock2 label {
  width: 20%;
}
.parentBlock2 .formGroup {
  display: flex;
  margin-bottom: 20px;
}
.formGroup .textarea01 {
  height: 150px;
  margin: 0;
  width: 80%;
  padding: 15px;
}
.formFiled {
  width: 80%;
  height: 43px;
  border-radius: 9px;
  border: solid 1px #d5d7e3;
  background-color: #ffffff;
  padding: 15px;
}
.addAssets {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #57b8ff;
  width: 125px;
  display: flex;
  align-items: center;
  justiy-content: flex-end;
  margin-left: auto;
  cursor: pointer;
}
.addAssets svg {
  width: 15px;
  height: 15px;
  margin: 0 5px 0 0;
}
.addAssets svg path {
  fill: #57b8ff;
}
.addItems {
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #bfc5e2;
}

.assetItems {
  margin-top: 15px;
  margin-left: 50px;
}
