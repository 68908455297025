.__Bid__ {
  .tenderHeader {
    justify-content: center;
    display: flex;
    counter-reset: list-number;
    margin: 30px 0;

  }
  .tenderHeader li {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
    color: #d5d7e3;
    position: relative;
    padding-right: 5px;
    border-bottom: 3px solid #7e84a3;
  }

  .tenderHeader li:before {
    counter-increment: list-number;
    content: counter(list-number);
    margin: 5px;
    margin-top: 0;
    width: 25px;
    height: 25px;
    font-size: 15px;
    background-color: #d5d7e3;
    border-radius: 50%;
    color: #fff;
  }
  .tenderHeader li.active {
    color: #57b8ff;
    border-bottom: 3px solid #57b8ff;
    cursor: pointer;
  }
  /* .tenderHeader li.active:after{ */
  /* content: ""; */
  /* background: #57b8ff; */
  /* height: 2px; */
  /* position: absolute; */
  /* left: -15px; */
  /* width: 198px; */
  /* top: 100%; */
  /* } */
  /* .tenderHeader li:nth-child(2).active:after{ */
  /* left: -32px; */
  /* width: 220px; */
  /* } */
  /* .tenderHeader li:nth-child(7).active:after{ */
  /* left: -16px; */
  /* width: 173px; */
  /* } */
  .tenderHeader li.active:before {
    color: #fff;
    background: #57b8ff;
  }
  .tenderHeader li.active.visited::before {
    background: url("../../assets/checkbox.svg") center center no-repeat;
    width: 25px;
    height: 25px;
    text-indent: -9999px;
    background-size: 100%;
  }
  .fieldcontainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      margin-bottom: 0;
    }
    input {
      max-width: 250px;
    }

    .item {
      padding-left: 30px;
    }

    .fieldcontainer__inputcontainer {
      display: flex;
      align-items: center;
      gap: 10px;
      position: relative;
    }
  }
  .price-root {
    width: 80%;
    margin: auto;
  }

  .radiogp {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;

    label {
      font-size: 1.2rem;
    }
  }
}

.bid-root {
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-direction: column;

  .tenderHeader {
    justify-content: center;
    display: flex;
    counter-reset: list-number;
    margin: 10px 0;
  }
  .tenderHeader li {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
    text-align: left;
    color: #d5d7e3;
    position: relative;
    padding-right: 5px;
    border-bottom: 3px solid #7e84a3;
    padding: 5px 19px;
  }

  .tenderHeader li:before {
    counter-increment: list-number;
    content: counter(list-number);

    margin-right: 7px;
    margin-bottom: 10px;
    width: 25px;
    height: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    background-color: #d5d7e3;
    border-radius: 50%;
    color: #fff;
  }
  .tenderHeader li.active {
    color: #57b8ff;
    border-bottom: 3px solid #57b8ff;
  }
  /* .tenderHeader li.active:after{ */
  /* content: ""; */
  /* background: #57b8ff; */
  /* height: 2px; */
  /* position: absolute; */
  /* left: -15px; */
  /* width: 198px; */
  /* top: 100%; */
  /* } */
  /* .tenderHeader li:nth-child(2).active:after{ */
  /* left: -32px; */
  /* width: 220px; */
  /* } */
  /* .tenderHeader li:nth-child(7).active:after{ */
  /* left: -16px; */
  /* width: 173px; */
  /* } */
  .tenderHeader li.active:before {
    color: #fff;
    background: #57b8ff;
  }
  .tenderHeader li.active.visited::before {
    background: url("../../assets/checkbox.svg") center center no-repeat;
    width: 25px;
    height: 25px;
    text-indent: -9999px;
    background-size: 100%;
  }

  h1 {
    margin: auto;
    margin-bottom: 20px;
    color: #9a9a9a;
  }

  .price {
    h2 {
    }

    .price-root {
      margin-bottom: 15px;
      .item {
        padding: 5px;
      }

      .item-full {
        margin-bottom: 15px;
      }
      .item-partial {
      }
    }
  }

  button {
    border-radius: 0px !important;
  }

  input {
    width: 100px;
    width: 100px;
    background: #ccc;
    border: none;
    padding: 5px;
    border-radius: 5px;
  }

  .files-upload {
    label {
      font-weight: bold;
      margin-right: 15px;
      font-size: 18px;
    }

    .item {
      padding: 10px;
      border-radius: 10px;
      border: 1px solid #ccc;
      margin-bottom: 15px;
      .img-preview {
        height: 200px;
      }
    }
  }

  .summary-root {
  }

  .ant-card {
    // margin-top: 20px;
    margin: auto;
  }
}

@primary-color: #57b8ff;