.__TenderCreate_ChooseSupplier__ {
  .flexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 50px;
  }

  .selectionbuttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .selectionsection {
    flex-grow: 1;
  }

  .invitationsection {
    margin-top: 20px;
    .invite {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      input {
        height: 35px;
        float: unset;
      }
      .btn {
        height: 35px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .invitecontainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      .invitedsupplier {
        margin: 10px 0;
        font-size: 1.1rem;
        color: grey;
      }
    }
  }
}

.parentBlock2.parentBlock3 {
  max-width: 50%;
  margin: 0 auto;
}
.parentBlock2 label {
  width: 50%;
  white-space: break-spaces;
}
.parentBlock2 .formGroup {
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}
.parentBlock2 .formGroup span {
  padding-left: 10px;
}
.formGroup .textarea01 {
  height: 150px;
  margin: 0;
  width: 50%;
  padding: 15px;
}
.formFiled {
  width: 50%;
  height: 43px;
  border-radius: 9px;
  border: solid 1px #d5d7e3;
  background-color: #ffffff;
  padding: 15px;
}
.formFiled.days {
  width: 85px;
}
.selectDate {
  position: relative;
  width: 50%;
}
.selectDate .calendraIcon {
  background: url("../../../assets/calendar.svg") left center no-repeat;
  position: absolute;
  right: 10px;
  top: 7px;
  width: 25px;
  height: 25px;
  background-size: 100%;
}
.selectDate .formFiled {
  width: 100%;
  padding: 0 45px 0 10px;
}
.parentBlock2 .formGroup .fillterTitle {
  padding-left: 0;
  font-size: 20px;
  font-weight: 600;
}
.selectDate .searchIcon {
  background: url("../../../assets/search.svg") left center no-repeat;
  position: absolute;
  right: 7px;
  top: 9px;
  width: 25px;
  height: 25px;
  background-size: 75%;
  cursor: pointer;
}
.selectDate .searchIcon:hover {
  opacity: 0.8;
}
.cardBlock {
  background: #fff;
  padding: 10px;
}

@primary-color: #57b8ff;