/* .tenderHeader{ */
/* justify-content: space-around; */
/* display: flex; counter-reset: list-number; */
/* border-bottom: 2px solid  #7e84a3;; */
/* } */
/* .tenderHeader li{ */
/* font-family: Poppins; */
/* font-size: 15px; */
/* font-weight: 500; */
/* font-stretch: normal; */
/* font-style: normal; */
/* line-height: normal; */
/* letter-spacing: 0.11px; */
/* text-align: left; */
/* color: #d5d7e3; */
/* position: relative; */

/* } */

/* .tenderHeader li:before { */
/* counter-increment: list-number; */
/* content: counter(list-number); */

/* margin-right: 7px; */
/* margin-bottom:10px; */
/* width: 25px; */
/* height: 25px; */
/* display:inline-flex; */
/* align-items:center; */
/* justify-content: center; */
/* font-size:15px; */
/* background-color:#d5d7e3; */
/* border-radius:50%; */
/* color:#fff; */
/* } */
/* .tenderHeader li.active{ */
/* color:#57b8ff; */
/* } */
/* .tenderHeader li.active:after{ */
/* content: ""; */
/* background: #57b8ff; */
/* height: 2px; */
/* position: absolute; */
/* left: -15px; */
/* width: 198px; */
/* top: 100%; */
/* } */
/* .tenderHeader li:nth-child(2).active:after{ */
/* left: -32px; */
/* width: 220px; */
/* } */
/* .tenderHeader li:nth-child(7).active:after{ */
/* left: -16px; */
/* width: 173px; */
/* } */
/* .tenderHeader li.active:before{ */
/* color: #fff; */
/* background:#57b8ff ; */
/* } */
/* .tenderHeader li.active.visited::before{ */
/* background: url("../../../assets/checkbox.svg") center center no-repeat; */
/* width: 25px; */
/* height: 25px; */
/* text-indent: -9999px; */
/* background-size: 100%; */
/* } */

.__AdditionalInfo__ .commentblock {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0;
}

.__AdditionalInfo__ .commentblock .left {
  font-family: Poppins;
  margin: 20px;
  margin-left: 0;
}

.__AdditionalInfo__ .commentblock .right {
  flex-grow: 1;
}

.__AdditionalInfo__ .commentblock .right textarea {
  border: none;
  height: 150px;
  width: 100%;
  margin: 0;
}

.hedding h2 {
  font-family: Poppins;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: 0.09px;
  text-align: center;
  color: #3f3f41;
  position: relative;
  padding-bottom: 5px;
}
.hedding h2:after {
  content: "";
  height: 3px;
  background: #3f3f41;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 50%;
  max-width: 100px;
  transform: translateX(-50%);
}

.parentBlock {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.textarea01 {
  width: 664.5px;
  height: 253px;
  margin: 0 0 30px 14.5px;
  border-radius: 5px;
  border: solid 1px #d5d7e3;
  background-color: #ffffff;
  resize: none;
}
