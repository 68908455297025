.loginOuter {
  max-width: 500px;
  margin: 0;
  background: #fff;
  padding: 2% 4% 2% 4%;
}
.loginOuter .formGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ht-logo2 {
  text-align: center;
  margin-bottom: 20px;
}
.loginOuter .form-control {
  border-radius: 5px;
  border: solid 1px #d5d7e3;
  background-color: #ffffff;
  width: 100%;
  font-family: Poppins;
  margin-bottom: 0;
  font-size: 16px;
}
.loginOuter .loginBtn {
  border-radius: 8px !important;
  height: 38px;
  background-color: #57b8ff;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.13px;
  text-align: left;
  color: #ffffff;
  padding: 0px 40px;
  text-align: center;
  cursor: pointer;
}
.loginOuter .loginBtn:hover {
  opacity: 0.8;
}
.loginOuter .labels {
  font-family: Poppins;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.35;
  letter-spacing: normal;
  text-align: center;
  color: #9c9c9c;
}
.errorMessageLogin {
  color: red;
}

.login__container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login__container .loginItem {
  margin-top: 15px;
}
.login__container .btnFormItem {
  margin-bottom: 5px;
}
